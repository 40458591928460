<template>
    <main class="cus-gift page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
        <banner-section location="giftcards_home-top"/>
        <section v-if="Array.isArray(giftCardsSuppliers) && giftCardsSuppliers.length" class="gift__brands">

            <div v-for="(supplier_item, index) in giftCardsSuppliers" :key="index" :style="{'background-color': supplier_item.logo_background_color}" class="brand communication__brand">
                <router-link :to="{name: 'GiftCardSupplier', params: {id: supplier_item.id }}" tag="a" class="link brand__link" exact>
                    <img v-if="supplier_item && supplier_item.hasOwnProperty('image') && supplier_item.image" class="brand__img communication__img" :src="supplier_item.image" alt="">
                    <b v-if="supplier_item && supplier_item.hasOwnProperty('name') && supplier_item.name" class="text brand__text communication__brand-text">{{ supplier_item.name }}</b>
                </router-link>
            </div>

        </section>

        <section v-if="Array.isArray(giftCardsSuppliersWithBestsellers) && giftCardsSuppliersWithBestsellers.length" class="gift__bestsellers">

            <h2 class="title gift__title">{{ $t("COMMUNICATION.BEST_SELLERS") }}</h2>

            <div class="gift__slider">

                <div class="gift__wrapper owl-carousel js-gift-bestsellers">

                    <div v-for="(supplier_item, supplier_index) in giftCardsSuppliersWithBestsellers" :key="supplier_index" class="category-slider ">
                        <router-link :to="{name: 'GiftCardSupplier', params: {id: supplier_item.id }}" tag="a" class="link" exact>
                            <div class="category-slider__img-wrapper gift__p-logo-wrapper" :style="{'background-color': supplier_item.logo_background_color}">
                                <img class="category-slider__img gift__p-logo" :src="supplier_item.image" alt="">
                            </div>
                        </router-link>
                        <div class="category-slider__info-block">

                            <div v-for="(bestseller, bestseller_index) in supplier_item.bestsellers" :key="bestseller_index" class="category-slider__info">

                                <router-link :to="{name: 'GiftCardProduct', params: {id: bestseller.id, supplier_id: bestseller.supplier_id }}" tag="a" class="link category-slider__link" exact>

                                    <p class="text category-slider__product-name text-two-line">
                                        {{ bestseller.name }}
                                    </p>

                                    <div v-if="bestseller.price != null" class="category-slider__price">
                                        <b class="category-slider__price-value">
                                            {{ ' ' + bestseller.price + ' ' }}
                                        </b>
                                        <b class="category-slider__current">
                                            {{ 'ש"ח' }}
                                        </b>
                                    </div>

                                </router-link>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="category-slider__controls ">

                    <button class="button category-slider__control category-slider__control_prev">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                    <button class="button category-slider__control category-slider__control_next">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                </div>

            </div>

        </section>

        <section v-if="Array.isArray(giftCardsSuppliersWithSpecials) && giftCardsSuppliersWithSpecials.length" class="gift__special">

            <h2 class="title gift__title">{{ $t("TOURISM.PROMOTIONS") }}</h2>

            <div class="gift__slider">

                <div class="gift__wrapper owl-carousel js-gift-special">
                    <div v-for="(supplier_item, supplier_index) in giftCardsSuppliersWithSpecials" :key="supplier_index"  class="category-slider">
                        <router-link :to="{name: 'GiftCardSupplier', params: {id: supplier_item.id }}" tag="a" class="link" exact>
                            <div class="category-slider__img-wrapper gift__p-logo-wrapper" :style="{'background-color': supplier_item.logo_background_color}">
                                <img class="category-slider__img gift__p-logo" :src="supplier_item.image" alt="">
                            </div>
                        </router-link>
                        <div class="category-slider__info-block">

                            <div v-for="(special, special_index) in supplier_item.specials" :key="special_index" class="category-slider__info">

                                <router-link :to="{name: 'GiftCardProduct', params: {id: special.id, supplier_id: special.supplier_id }}" tag="a" class="link category-slider__link" exact>

                                    <p class="text category-slider__product-name">
                                        {{ special.name }}
                                    </p>

                                    <div v-if="special.price != null" class="category-slider__price">
                                        <b class="category-slider__price-value">
                                            {{ ' ' + special.price + ' ' }}
                                        </b>
                                        <b class="category-slider__current">
                                            {{ 'ש"ח' }}
                                        </b>
                                    </div>

                                </router-link>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="category-slider__controls ">

                    <button class="button category-slider__control category-slider__control_prev">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                    <button class="button category-slider__control category-slider__control_next">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                </div>

            </div>

        </section>

    </main>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import appConfig from '../../appConfig'
    import BannerSection from "../../components/BannerSection";

    export default {
        name: 'GiftCard',
        components: {
            BannerSection,
        },
        data: () => {
            return {
                reInitBestsellersCarouselNeeded: false,
                reInitSpecialsCarouselNeeded: false
            }
        },
        mounted() {
            moduleGift.init()
        },
        computed: {
            ...mapState({
                supplier_items: state => state.supplier.supplier_items,
                suppliers_products: state => state.supplier.suppliers_products,
                product_bestseller_tag_id: state => state.supplier.product_bestseller_tag_id,
                product_special_tag_id: state => state.supplier.product_special_tag_id,
            }),
            giftCardsSuppliers() {
                let giftCardsSuppliers = this.supplier_items && this.supplier_items.hasOwnProperty('gift_cards') && Array.isArray(this.supplier_items.gift_cards) && this.supplier_items.gift_cards.length ? this.supplier_items.gift_cards : [];
                giftCardsSuppliers = giftCardsSuppliers.filter(supplier => supplier.status === 1);

                return giftCardsSuppliers;
            },
            giftCardsSuppliersWithBestsellers() {
                let giftCardsSuppliersWithBestsellers = []

                if (this.giftCardsSuppliers && this.giftCardsSuppliers.length && this.product_bestseller_tag_id) {
                    for (let supplier of this.giftCardsSuppliers) {
                        let supplier_products = this.suppliers_products.hasOwnProperty(supplier.id)
                            ? this.suppliers_products[supplier.id] : []
                        let supplier_bestsellers = []

                        if (supplier_products) {
                            for (let supplier_product_type in supplier_products) {
                                for (let supplier_product of supplier_products[supplier_product_type]) {
                                    if (supplier_product.hasOwnProperty('tags') && supplier_product.tags.length
                                        && supplier_product.tags.includes(this.product_bestseller_tag_id)) {
                                        supplier_bestsellers.push(supplier_product)
                                    }
                                }
                            }
                        }

                        if (supplier_bestsellers.length) {
                            giftCardsSuppliersWithBestsellers.push({...supplier, bestsellers: supplier_bestsellers})
                        }
                    }
                }

                let obj = this
                obj.reInitBestsellersCarouselNeeded = true
                setTimeout(function () {
                    if (obj.reInitBestsellersCarouselNeeded) {
                        obj.reInitBestsellersCarouselNeeded = false
                        obj.reInitBestsellersCarousel()
                    }
                }, 500)

                return giftCardsSuppliersWithBestsellers
            },
            giftCardsSuppliersWithSpecials() {
                let giftCardsSuppliersWithSpecials = []

                if (this.giftCardsSuppliers && this.giftCardsSuppliers.length && this.product_special_tag_id) {
                    for (let supplier of this.giftCardsSuppliers) {
                        let supplier_products = this.suppliers_products.hasOwnProperty(supplier.id)
                            ? this.suppliers_products[supplier.id] : []
                        let supplier_specials = []

                        if (supplier_products) {
                            for (let supplier_product_type in supplier_products) {
                                for (let supplier_product of supplier_products[supplier_product_type]) {
                                    if (supplier_product.hasOwnProperty('tags') && supplier_product.tags.length
                                        && supplier_product.tags.includes(this.product_special_tag_id)) {
                                        supplier_specials.push(supplier_product)
                                    }
                                }
                            }
                        }

                        if (supplier_specials.length) {
                            giftCardsSuppliersWithSpecials.push({...supplier, specials: supplier_specials})
                        }
                    }
                }

                let obj = this
                obj.reInitSpecialsCarouselNeeded = true
                setTimeout(function () {
                    if (obj.reInitSpecialsCarouselNeeded) {
                        obj.reInitSpecialsCarouselNeeded = false
                        obj.reInitSpecialsCarousel()
                    }
                }, 500)

                return giftCardsSuppliersWithSpecials
            }
        },
        created () {
            this.getAllSupplierItems({params: {filter: true}})
            this.getProductTags()
        },
        methods: {
            ...mapActions('supplier', {
                getAllSupplierItems: 'getAll',
                getProductTags: 'getProductTags',
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            reInitBestsellersCarousel() {
                $('.js-gift-bestsellers').each(function () {
                    $(this).trigger('destroy.owl.carousel');
                })
                moduleGift.init()
            },
            reInitSpecialsCarousel() {
                $('.js-gift-special').each(function () {
                    $(this).trigger('destroy.owl.carousel');
                })
                moduleGift.init()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .gift__brands {
        justify-content: flex-start;
    }
    .cus-gift {
        flex-basis: 100%;
        padding: 0 90px 50px;
        box-sizing: border-box;
        overflow: hidden;
    }
    .category-slider__product-name {
        max-width: 72%;
    }
    .category-slider__link::before {
        top: 50%;
        transform: translateY(-50%);
    }
    .category-slider__link::after {
        top: 50%;
        transform: translateY(-50%);
        width: 21px;
        height: 21px;
    }
    .category-slider__img-wrapper {
        height: 80px;
        padding: 10px;
    }
    .brand__img {
        position: absolute;
        top: 0;
        height: calc(100% - 24px);
    }
    .communication__brand-text {
        width: 100%;
        height: 24px;
        bottom: 0;
        background: rgba(0,0,0,0.2);
    }
    .communication__brand {
        flex-basis: calc((100% - 100px) / 6);
        margin: 10px;
        box-sizing: border-box;
    }
    @media screen and (min-width: 1366px) {
        .communication__brand:nth-child(n+7) {
            margin-top: 10px;
        }
        .communication__brand:nth-child(6n+1) {
            margin-right: 0;
        }
        .communication__brand:nth-child(6n+6) {
            margin-left: 0;
        }
    }
     @media screen and (max-width: 1365px) and (min-width: 768px) {
        .communication__brand {
            flex-basis: calc((100% - 60px) / 4);
        }
        .communication__brand:nth-child(n + 5) {
            margin-top: 10px;
        }
        .communication__brand:nth-child(4n + 1) {
            margin-right: 0;
        }

        .communication__brand:nth-child(4n + 4) {
            margin-left: 0;
        }
    }
    @media screen and (max-width: 767px) {
        .communication__brand {
            flex-basis: calc((100% - 40px) / 4);
            margin: 5px;
            height: 90px;
        }
       .cus-gift {
           padding: 0;
       } 
       .gift__brand {
           flex-basis: calc(25% - 5px);
           height: 60px;
       }
       .gift__bestsellers,
       .gift__special {
           margin-top: 20px;
       }
    }
</style>